h4 {
  margin-bottom: 1.38rem;
}
.text {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.4em;
  margin: 1rem;
  min-height: 80vh;
  margin-bottom: 10rem;
}

.text>p {
  margin: 2rem;
  padding: 1rem;
}

.text>li {
  padding: 1em 0;
}

.title {
  font-size: 2.5rem;
  font-weight: 700;
  text-align: center;
  margin: 1rem;
}

.form {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
  /* margin-bottom: 10rem; */
  /* width: 100vw; */
}

.form>label {
  line-height: 2.24rem;
  /* font-weight: bold; */
  margin-top: 2rem;
  font-size: 1.6rem;
}

.sectionTitle {
  font-size: 1.75rem;
  font-weight: 700;
  margin-bottom: 2rem;
}

.formSection {
  background-color: #00626D30;
  border-radius: 1rem;
  padding: 3rem;
  margin: 1rem 0;
  width: 100%;
  max-width: 800px;
  font-size: 2rem;
}

.responsiveDiv {
  display: table;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}

.tickSubSection {
  display: flex;
  flex-direction: column;
}

.innieLink {
  text-decoration: none;
  color: #00626d;
}

.innieLink>span {
  border-bottom: 1px solid #ebb600;
}

.innieLink:hover {
  border-bottom: 0px;
}

.specialText {
  font-weight: 700;
  display: inline;
  margin: 2rem 0;
  display: block;
  color: #cc0033;
}

.checkBoxDiv {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.specCard {
  display: flex;
  justify-content: center;
  gap: 1rem;
  padding: 1rem 2rem;
}

.specCard span {
  padding: 1.6rem;
  font-weight: bold;
}

.input {
  padding: 1rem;
  margin-left: 1rem;
  width: 6rem;
  border-radius: 0.5rem;
  border: 0.5px solid black;
}

.summary {
  list-style: none;
  display: inline-flex;
  align-items: center;
  padding: 10px;
  font-weight: bold;
}

.summary::after {
  content: '';
  width: 18px;
  height: 10px;
  background: url('https://uploads.sitepoint.com/wp-content/uploads/2023/10/1697699669arrow.svg') no-repeat;
  background-size: cover;
  margin-left: 0.75em;
  transition: 0.2s;
}

.details[open]>summary::after {
  transform: rotate(180deg);
}

.summary::-webkit-details-marker {
  display: none;
}

.summary {
  background-color: #00626D30;
  color: #30353b;
  border-radius: 5px;
}

.details[open] summary {
  border-radius: 5px 5px 0 0;
}

.details {
  border-radius: 5px;
  margin: 1rem 0;
  max-width: 800px;
}

.submitBtn {
  font-size: 1.8rem;
  width: 22.5rem;
  height: 5rem;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  border: none;
  box-shadow: 0 3px 15px #00000016;
  margin: 1rem;
  background-color: #00626D40;
  /* padding: ${({ padding }) => padding}; */
}

.submitBtn:hover {
  opacity: 0.9;
  transform: scale(0.98);
}

.submitSpan {
  font-weight: bold;
  text-align: center;
  font-size: 1.8rem;
  color: #00626D;
}

.btnDiv {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  margin: 2rem 0;
  align-items: center;
  margin-bottom: 10rem;
}

.multiDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.multiImg {
  max-width: 800px;
  margin-top: 5rem;
}

.multiMsg {
  font-size: 1.5rem;
  margin: 2rem;

}

.errorDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  padding: 1rem;
}

.userLoc {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}
.userInputSection {
  
}

@media screen and (max-width: 748px) {
  .userLoc {
    flex-wrap: wrap;   
  }
  .text {
    width: 100%;
  }

  .form input {
    width: 90%;
  }

  .form input::placeholder {
    font-size: 1.2rem;
  }

  .formSection {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 300px;
  }

  .responsiveDiv {
    max-width: 80%;
    display: flex;
    flex-direction: column;
  }

  .btnDiv {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (min-width: 810px) {
  .text {
    width: 800px;
    text-align: left;
  }
}

@media screen and (min-width: 1010px) {
  .text {
    width: 1000px;
    text-align: left;
  }
}

.donateLink {
  text-decoration: underline;
}