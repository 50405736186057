.pageCont {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.4em;
  margin: 1rem;
  margin-bottom: 10rem;
  min-height: 80vh;
}

 

   table {
     font-family: sans-serif;
     border-collapse: collapse;
     width: 100%;
   }
 
   table td,
   table th {
     border: 1px solid #ddd;
     padding: 1rem;
   }
 
   table tr:nth-child(even) {
     background-color: #f5f3f2;
   }
 
   table tr:hover {
     background-color: #e5e2e0;
   }
 
   table th {
     padding-top: 1.5rem;
     padding-bottom: 1.5rem;
     text-align: center;
     background-color: #137b80;
     color: white;
   }


@media screen and (max-width: 768px) {
  .pageCont {
    width: 100%;
  }
}

@media screen and (min-width: 810px) {
  .pageCont {
    width: 800px;
    text-align: left;
  }
}
@media screen and (min-width: 1010px) {
  .pageCont {
    width: 1000px;
    text-align: left;
  }
}
