 .inputDiv {
     display: flex;
    justify-content: center;
 }
 .inputDiv input {
     padding: 0.5rem;
 }

 .table {
 font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
 }
 .table td, th {
    border: 1px solid #ddd;
  padding: 0.8rem;
  text-align: center;
  }
  .table tr:nth-child(even){background-color: #f2f2f2;}

  .table tr:hover {background-color: #ddd;}
 
  .table th {
   padding-top: 1.2rem;
   padding-bottom: 1.2rem;
   background-color: #00626d;
   color: white;
 }
 .table td {
    color: black;
    }

    .container {
        display: flex;
        flex-direction: column;
        width: 80vw;
        max-width: 900px;
    
    }
    .title {
        text-align: center;
    }
 