:root {
    --color-ruTeal: #00626d;
    --color-main: #ebb600;
    --color-grey3: #808080;
    --width-mobile: 768px;
    --border-radius: 1rem;
}

:global(.link) {
text-decoration: none;
color: var(--color-ruTeal);
}


:global(.link:hover) {
    border-bottom: 0px;
}


