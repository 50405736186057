.formDiv {
  margin-bottom: 2rem;
  font-size: 2rem;
}
.radioButtons {
    display: flex;
    width: 90%;
    flex-wrap: wrap; 
}

 /*
    Hide radio button (the round disc)
    we will use just the label to create pushbutton effect
  */
.radioButtons input[type="radio"] {
  display: none;
}
  
    /*
    Change the look'n'feel of labels (which are adjacent to radiobuttons).
    Add some margin, padding to label
  */
    .radioButtons input[type="radio"] + label {
      margin-top: 0.5rem;
      display: inline-block;
      padding: 0.5rem;
      color: #333;
      background-color: #fff;
      border: 0.5px solid #333;
    }
    /*
   Change background color for label next to checked radio button
   to make it look like highlighted button
  */
    .radioButtons input[type="radio"]:checked + label {
      background-image: none;
      background-color: #00626d;
      color: #fff;
    }
    